<script>
import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    stages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1.2,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 10,
            bottom: 10,
          },
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItem, data) => {
              const stageCode = data.labels[tooltipItem.index];
              const value = data.datasets[0].data[tooltipItem.index];
              const stage = (this.stages || []).find(
                (s) => s.stageCode === stageCode
              );
              const stageName = stage
                ? stage.name
                : this.getDefaultStageName(stageCode);
              return `${stageName}: ${value}`;
            },
          },
        },
        cutoutPercentage: 65,
      },
    };
  },
  methods: {
    getDefaultStageName(code) {
      const names = {
        NEW: "New Case",
        INVE: "Investigation",
        PEDI: "Pending Edit",
        CLO: "Closed",
        CANC: "Cancelled",
      };
      return names[code] || code;
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
