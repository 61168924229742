<template>
  <v-container fluid class="dashboard-container">
    <div class="dashboard-grid" v-if="currentUser">
      <!-- Welcome Bar -->
      <div class="welcome-bar">
        <v-card class="mb-4">
          <v-card-text>
            <div class="d-flex align-center">
              <span class="text-h4"
                >Welcome back, {{ currentUser.username }}!</span
              >
              <v-spacer></v-spacer>
              <v-switch
                v-model="showOverdueOnLogin"
                label="Show Overdue Alerts on Login"
                @change="toggleOverdueAlerts"
              ></v-switch>
            </div>
            <v-divider class="my-3"></v-divider>
            <div class="d-flex align-center flex-wrap justify-space-between">
              <!-- Status Information -->
              <div class="status-info d-flex align-center">
                <template
                  v-if="
                    overdueItems.tatAlert.length || overdueItems.tatMax.length
                  "
                >
                  <v-chip color="warning" class="mr-2">
                    {{ overdueItems.tatAlert.length }} Alert Level Cases
                  </v-chip>
                  <v-chip color="error" class="mr-4">
                    {{ overdueItems.tatMax.length }} Max Level Cases
                  </v-chip>
                </template>
              </div>

              <!-- Quick Actions -->
              <div class="quick-actions">
                <v-btn
                  text
                  color="primary"
                  class="px-2"
                  @click="$router.push('/home')"
                >
                  <v-icon left>mdi-folder-multiple</v-icon>
                  View Cases
                </v-btn>
                <v-divider vertical class="mx-2" />
                <v-btn
                  text
                  color="success"
                  class="px-2"
                  @click="$router.push('/archive')"
                >
                  <v-icon left>mdi-archive</v-icon>
                  Archives
                </v-btn>
                <v-divider vertical class="mx-2" />
                <v-btn
                  text
                  color="info"
                  class="px-2"
                  @click="$router.push('/profile')"
                >
                  <v-icon left>mdi-account</v-icon>
                  Profile
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>

      <!-- Notification Bar with Quick Actions -->
      <div class="notification-bar">
        <v-row>
          <v-col
            v-for="(stat, index) in statisticsCards"
            :key="index"
            cols="12"
            sm="6"
            md="2"
          >
            <v-card :color="stat.color">
              <v-card-text>
                <div class="text-h4 font-weight-bold white--text">
                  {{ stat.value }}
                </div>
                <div class="white--text">{{ stat.label }}</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <!-- Analytics Section - Modified to use full width -->
      <div class="analytics-section">
        <!-- First Row - Activity Cards -->
        <v-row>
          <!-- Recent Activities -->
          <v-col cols="12" md="4">
            <v-card class="activity-card">
              <v-card-title class="text-subtitle-1">
                Recent Activities
                <v-spacer></v-spacer>
                <v-btn icon x-small @click="refreshActivities">
                  <v-icon small>mdi-refresh</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="pa-0">
                <template v-if="recentActivities.length > 0">
                  <v-list dense>
                    <v-list-item
                      v-for="activity in recentActivities"
                      :key="activity.id"
                      class="py-2"
                    >
                      <v-list-item-icon>
                        <v-icon :color="activity.color">{{
                          activity.icon
                        }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-body-2 font-weight-medium"
                        >
                          {{ activity.fileNo }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="d-flex align-center">
                          <span>{{ activity.action }}</span>
                          <v-spacer></v-spacer>
                          <span class="text-caption">{{
                            activity.timestamp
                          }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </template>
                <template v-else>
                  <v-list-item>
                    <v-list-item-content class="text-center py-6">
                      <v-icon large color="grey lighten-1" class="mb-2"
                        >mdi-refresh</v-icon
                      >
                      <div class="text-body-2 grey--text">
                        Click the refresh button above to load recent activities
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Quick Access  -->
          <v-col cols="12" md="4">
            <v-card class="deadline-card">
              <v-card-title class="text-subtitle-1">
                Quick Access
                <v-spacer></v-spacer>
                <v-btn icon x-small @click="$router.push('/listing')">
                  <v-icon small>mdi-view-list</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="pa-2">
                <v-card outlined class="mb-2">
                  <v-card-subtitle class="py-1 px-2">
                    <div class="d-flex align-center">
                      <v-icon small color="primary" class="mr-1"
                        >mdi-folder-account</v-icon
                      >
                      <span class="text-body-2 font-weight-medium"
                        >Departments</span
                      >
                    </div>
                  </v-card-subtitle>
                  <v-card-text class="py-2 px-2">
                    <div class="d-flex flex-wrap">
                      <template v-if="filteredDepartments.length">
                        <v-chip
                          v-for="dept in filteredDepartments"
                          :key="dept.id"
                          outlined
                          small
                          class="ma-1"
                          color="primary"
                          @click="navigateToDepartment(dept.id)"
                        >
                          {{ dept.name }}
                        </v-chip>
                      </template>
                      <v-chip v-else outlined small disabled class="ma-1">
                        No departments available
                      </v-chip>
                    </div>
                  </v-card-text>
                </v-card>

                <v-card outlined>
                  <v-card-subtitle class="py-1 px-2">
                    <div class="d-flex align-center">
                      <v-icon small color="success" class="mr-1"
                        >mdi-office-building</v-icon
                      >
                      <span class="text-body-2 font-weight-medium"
                        >Branches</span
                      >
                    </div>
                  </v-card-subtitle>
                  <v-card-text class="py-2 px-2">
                    <div class="d-flex flex-wrap">
                      <template v-if="filteredBranches.length">
                        <v-chip
                          v-for="branch in filteredBranches"
                          :key="branch.id"
                          outlined
                          small
                          class="ma-1"
                          color="success"
                          @click="navigateToBranch(branch.id)"
                        >
                          {{ branch.name }}
                        </v-chip>
                      </template>
                      <v-chip v-else outlined small disabled class="ma-1">
                        No branches available
                      </v-chip>
                    </div>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Quick Find Card -->
          <v-col cols="12" md="4">
            <v-card class="quick-find-card">
              <v-card-title class="text-subtitle-1">
                Quick Find
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon x-small v-bind="attrs" v-on="on">
                      <v-icon small>mdi-help-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Find cases by ID, keyword, or filter by type</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text>
                <!-- Find Case by ID -->
                <v-text-field
                  v-model="caseIdSearch"
                  dense
                  outlined
                  label="Case ID"
                  placeholder="Enter Case ID"
                  hint="Case ID must be numeric"
                  @keypress.enter="findCaseById"
                  hide-details="auto"
                  class="mb-2"
                >
                  <template v-slot:append>
                    <v-btn
                      icon
                      small
                      color="primary"
                      :disabled="!caseIdSearch || !caseIdSearch.match(/^\d+$/)"
                      @click="findCaseById"
                    >
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>

                <!-- Advanced Search Section -->
                <v-expansion-panels flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="py-1">
                      <div class="text-subtitle-2">Advanced Search</div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <!-- Keyword Search -->
                      <v-text-field
                        v-model="keywordSearch"
                        dense
                        outlined
                        label="Keyword Search"
                        placeholder="Search by keywords"
                        hide-details="auto"
                        class="mb-2"
                        prepend-inner-icon="mdi-text-search"
                      ></v-text-field>

                      <!-- Department Filter -->
                      <v-select
                        v-model="selectedDepartment"
                        :items="departmentsWithAll"
                        item-text="name"
                        item-value="id"
                        label="Department"
                        dense
                        outlined
                        clearable
                        hide-details="auto"
                        class="mb-2"
                        prepend-inner-icon="mdi-folder-account"
                      ></v-select>

                      <!-- Branch Filter -->
                      <v-select
                        v-model="selectedBranch"
                        :items="branchesWithAll"
                        item-text="name"
                        item-value="id"
                        label="Branch"
                        dense
                        outlined
                        clearable
                        hide-details="auto"
                        class="mb-2"
                        prepend-inner-icon="mdi-office-building"
                      ></v-select>

                      <!-- Insurer Filter -->
                      <v-autocomplete
                        v-model="selectedInsurer"
                        :items="insurersWithAll"
                        item-text="name"
                        item-value="id"
                        label="Insurer"
                        dense
                        outlined
                        clearable
                        hide-details="auto"
                        class="mb-2"
                        prepend-inner-icon="mdi-shield-account"
                      ></v-autocomplete>

                      <!-- Status Filter -->
                      <v-select
                        v-model="selectedStatus"
                        :items="stagesForFilter"
                        item-text="name"
                        item-value="stageCode"
                        label="Status"
                        dense
                        outlined
                        clearable
                        hide-details="auto"
                        class="mb-2"
                        prepend-inner-icon="mdi-checkbox-marked-circle-outline"
                      ></v-select>

                      <!-- Search Button -->
                      <v-btn
                        block
                        color="primary"
                        :disabled="
                          !keywordSearch &&
                          !selectedDepartment &&
                          !selectedBranch &&
                          !selectedInsurer &&
                          !selectedStatus
                        "
                        @click="performAdvancedSearch"
                        :loading="advancedSearchLoading"
                      >
                        <v-icon left>mdi-magnify</v-icon>
                        Search
                      </v-btn>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <!-- Recent Searches -->
                <v-card-subtitle
                  class="pt-3 pb-1 px-0"
                  v-if="recentSearches.length"
                >
                  <div class="d-flex align-center">
                    <v-icon small color="grey" class="mr-1">mdi-history</v-icon>
                    <span class="text-body-2 font-weight-medium"
                      >Recent Searches</span
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      x-small
                      text
                      color="grey"
                      @click="clearRecentSearches"
                    >
                      Clear
                    </v-btn>
                  </div>
                </v-card-subtitle>

                <div class="d-flex flex-wrap" v-if="recentSearches.length">
                  <v-chip
                    v-for="(search, index) in recentSearches.slice(0, 3)"
                    :key="index"
                    small
                    class="ma-1"
                    close
                    @click="repeatSearch(search)"
                    @click:close="removeRecentSearch(index)"
                  >
                    {{ search.text }}
                  </v-chip>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- Second Row - Charts -->
        <v-row class="mt-4">
          <v-col
            cols="12"
            class="d-flex justify-center align-center mb-2"
            v-if="!chartsLoaded"
          >
            <v-btn color="primary" @click="loadCharts" :loading="chartsLoading">
              <v-icon left>mdi-chart-box</v-icon>
              Load Charts Data
            </v-btn>
          </v-col>
          <template v-if="chartsLoaded">
            <v-col
              v-for="(chart, index) in charts"
              :key="index"
              cols="12"
              md="4"
            >
              <v-card class="chart-card">
                <v-card-title class="text-subtitle-1">
                  {{ chart.title }}
                  <v-spacer></v-spacer>
                  <v-btn icon x-small @click="refreshSingleChart(index)">
                    <v-icon small>mdi-refresh</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="chart-container">
                  <v-skeleton-loader
                    v-if="!chart.data"
                    type="table-heading, list-item-two-line, image, table-tfoot"
                    class="mx-auto"
                  ></v-skeleton-loader>
                  <component
                    v-else
                    :is="chart.component"
                    :chart-data="chart.data"
                    :stages="stages"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </template>
          <v-col cols="12" class="text-center" v-if="!chartsLoaded">
            <v-card class="pa-4">
              <v-card-text class="d-flex flex-column align-center">
                <v-icon large color="grey lighten-1" class="mb-2"
                  >mdi-chart-bar</v-icon
                >
                <div class="text-body-1 grey--text">
                  Click the button above to load chart data
                </div>
                <div class="text-caption grey--text text--lighten-1 mt-2">
                  Loading charts on demand improves dashboard performance
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else>
      <v-card class="text-center pa-4">
        <v-card-text> Please log in to view the dashboard </v-card-text>
      </v-card>
    </div>

    <!-- Add overdue-alert component -->
    <overdue-alert
      :show="showOverdueAlert"
      :overdueAlertData="overdueItems.tatAlert"
      :overdueMaxData="overdueItems.tatMax"
      :refType="refType"
      :subRefTypes="subRefTypes"
      :branch="branch"
      :stages="stages"
      :currentUser="$store.state.auth.user"
      @close="showOverdueAlert = false"
    />

    <!-- Add this after your existing container content -->
    <v-snackbar
      v-model="processSnackbar.show"
      :timeout="processSnackbar.timeout"
      :color="processSnackbar.color"
      top
      :style="{ left: '50%', transform: 'translateX(-50%)' }"
      class="notification-snackbar"
    >
      <v-alert
        :value="true"
        :color="processSnackbar.color"
        :icon="getSnackbarIcon"
        class="mb-0"
        dense
        outlined
        text
      >
        <div class="d-flex align-center">
          <v-progress-circular
            v-if="processSnackbar.loading"
            indeterminate
            size="20"
            width="2"
            :color="processSnackbar.color"
            class="mr-3"
          ></v-progress-circular>
          <span :class="`${processSnackbar.color}--text`">{{
            processSnackbar.text
          }}</span>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!processSnackbar.loading"
            icon
            x-small
            :color="processSnackbar.color"
            @click="processSnackbar.show = false"
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-alert>
    </v-snackbar>
  </v-container>
</template>

<script>
import CasefileDataService from "../services/CasefileDataService";
import TatChartDataService from "../services/TatChartDataService";
import DeptDataService from "../services/DeptDataService";
import SubRefTypeDataService from "../services/SubDeptDataService";
import BranchDataService from "../services/BranchDataService";
import StagesDataService from "../services/StageDataService";
import OverdueAlert from "../components/overdue/OverdueAlert.vue";
import moment from "moment";
import InsDataService from "../services/InsDataService";
import DepartmentPieChart from "../components/charts/DepartmentPieChart.vue";
import InsurerBarChart from "../components/charts/InsurerBarChart.vue";
import StatusDonutChart from "../components/charts/StatusDonutChart.vue";

export default {
  name: "Welcome",
  components: {
    OverdueAlert,
    DepartmentPieChart,
    InsurerBarChart,
    StatusDonutChart,
  },
  data: () => ({
    totalActiveCases: 0,
    overdueCount: 0,
    completedThisMonth: 0,
    newCasesToday: 0,
    showOverdueAlert: false,
    showOverdueOnLogin: false,
    overdueItems: {
      tatAlert: [],
      tatMax: [],
    },
    refType: [],
    subRefTypes: [],
    branch: [],
    stages: [],
    tatCharts: [],
    pendingInvestigation: 0,
    pendingEditing: 0,
    processSnackbar: {
      show: false,
      text: "",
      color: "info",
      timeout: 3000,
      loading: false,
    },
    processingComplete: false,
    // Chart data properties
    departmentChartData: null,
    insurerChartData: null,
    statusChartData: null,
    insurer: [],
    charts: [
      {
        title: "Department Distribution",
        component: "department-pie-chart",
        data: null,
        ref: "departmentChartData",
      },
      {
        title: "Top 5 Insurers",
        component: "insurer-bar-chart",
        data: null,
        ref: "insurerChartData",
      },
      {
        title: "Case Status Distribution",
        component: "status-donut-chart",
        data: null,
        ref: "statusChartData",
      },
    ],
    recentActivities: [],
    upcomingDeadlines: [],
    quickTasks: [],
    // Add Quick Find data properties
    caseIdSearch: "",
    keywordSearch: "",
    selectedDepartment: null,
    selectedBranch: null,
    selectedStatus: null,
    selectedInsurer: null, // Add this line
    recentSearches: [],
    advancedSearchLoading: false, // Add loading state for advanced search
    chartsLoaded: false,
    chartsLoading: false,
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user || null;
    },
    statisticsCards() {
      return [
        {
          label: "Active Cases",
          value: this.totalActiveCases,
          color: "primary",
        },
        { label: "Overdue Cases", value: this.overdueCount, color: "warning" },
        {
          label: "Completed This Month",
          value: this.completedThisMonth,
          color: "success",
        },
        { label: "New Cases Today", value: this.newCasesToday, color: "info" },
        {
          label: "Under Investigation",
          value: this.pendingInvestigation,
          color: "deep-orange",
        },
        {
          label: "Pending Editing",
          value: this.pendingEditing,
          color: "purple",
        },
      ];
    },
    getSnackbarIcon() {
      switch (this.processSnackbar.color) {
        case "success":
          return "mdi-check-circle";
        case "error":
          return "mdi-alert-circle";
        case "warning":
          return "mdi-alert";
        default:
          return "mdi-information";
      }
    },
    filteredDepartments() {
      if (!this.currentUser || !this.refType || this.refType.length === 0) {
        return [];
      }

      if (
        this.currentUser.roles &&
        this.currentUser.roles.some((role) =>
          ["ADMIN", "MANAGER", "CLERK"].includes(role)
        )
      ) {
        return this.refType;
      }

      if (this.currentUser.dept && this.currentUser.dept.length > 0) {
        return this.refType.filter((dept) =>
          this.currentUser.dept.includes(dept.id)
        );
      }

      return [];
    },

    filteredBranches() {
      if (!this.currentUser || !this.branch || this.branch.length === 0) {
        return [];
      }

      if (
        this.currentUser.roles &&
        this.currentUser.roles.some((role) =>
          ["ADMIN", "MANAGER", "CLERK"].includes(role)
        )
      ) {
        return this.branch;
      }

      if (this.currentUser.branches && this.currentUser.branches.length > 0) {
        return this.branch.filter((branch) =>
          this.currentUser.branches.includes(branch.id)
        );
      }

      return [];
    },

    // Add a new computed property for filtering stages for the dropdown
    stagesForFilter() {
      // Filter out duplicate stages or any you don't want to show
      if (!this.stages || this.stages.length === 0) {
        return [{ stageCode: "all", name: "ALL" }];
      }

      // Filter active statuses and sort them for the dropdown
      const activeStatuses = this.stages.filter(
        (stage) =>
          stage.stageCode !== "CLO" &&
          stage.stageCode !== "CANC" &&
          stage.name &&
          stage.stageCode
      );

      // Sort by name for better user experience
      return [
        { stageCode: "all", name: "ALL" },
        ...activeStatuses.sort((a, b) => a.name.localeCompare(b.name)),
      ];
    },

    departmentsWithAll() {
      // Add "ALL" option to departments
      if (!this.filteredDepartments || this.filteredDepartments.length === 0) {
        return [{ id: "all", name: "ALL" }];
      }
      return [{ id: "all", name: "ALL" }, ...this.filteredDepartments];
    },

    branchesWithAll() {
      // Add "ALL" option to branches
      if (!this.filteredBranches || this.filteredBranches.length === 0) {
        return [{ id: "all", name: "ALL" }];
      }
      return [{ id: "all", name: "ALL" }, ...this.filteredBranches];
    },

    insurersWithAll() {
      // Add "ALL" option to insurers
      if (!this.insurer || this.insurer.length === 0) {
        return [{ id: "all", name: "ALL" }];
      }
      return [{ id: "all", name: "ALL" }, ...this.insurer];
    },
  },

  watch: {
    // Add watchers to update chart data
    departmentChartData(val) {
      this.charts[0].data = val;
    },
    insurerChartData(val) {
      this.charts[1].data = val;
    },
    statusChartData(val) {
      this.charts[2].data = val;
    },
  },

  methods: {
    async loadStatistics() {
      try {
        this.showProcessUpdate("Loading case statistics...");
        // Load required data for overdue alerts
        await Promise.all([
          this.retrieveTatCharts(),
          this.retrieveDept(),
          this.retrieveSubRefType(),
          this.retrieveBranches(),
          this.retrieveStages(),
        ]);

        const response = await CasefileDataService.getAll();
        const cases = response.data;
        const today = moment().startOf("day");
        const monthStart = moment().startOf("month");

        this.totalActiveCases = cases.filter(
          (c) => c.fileStatus !== "CLO" && c.fileStatus !== "CANC"
        ).length;

        this.newCasesToday = cases.filter((c) =>
          moment(c.dateOfAssign).isSame(today, "day")
        ).length;

        this.completedThisMonth = cases.filter(
          (c) =>
            c.fileStatus === "CLO" && moment(c.dateOfAssign).isAfter(monthStart)
        ).length;

        this.overdueCount = cases.filter((c) => {
          const days = moment().diff(moment(c.dateOfAssign), "days");
          return days > 30 && c.fileStatus !== "CLO" && c.fileStatus !== "CANC";
        }).length;

        this.pendingInvestigation = cases.filter(
          (c) => c.fileStatus === "INVE"
        ).length;

        this.pendingEditing = cases.filter(
          (c) => c.fileStatus === "PEDI"
        ).length;

        // Check for overdue cases
        this.checkOverdueCases(cases);

        this.showProcessUpdate("Case statistics loaded", false, "success");
      } catch (error) {
        this.showProcessUpdate("Error loading statistics", false, "error");
        console.error("Error loading statistics:", error);
      }
    },

    async retrieveTatCharts() {
      const response = await TatChartDataService.getAll();
      this.tatCharts = response.data;
    },

    async retrieveDept() {
      const response = await DeptDataService.getAll();
      this.refType = response.data;
    },

    async retrieveSubRefType() {
      const response = await SubRefTypeDataService.getAll();
      this.subRefTypes = response.data;
    },

    async retrieveBranches() {
      const response = await BranchDataService.getAll();
      this.branch = response.data;
    },

    async retrieveStages() {
      const response = await StagesDataService.getAll();
      this.stages = response.data;
    },

    async retrieveIns() {
      try {
        const response = await InsDataService.getAll();
        this.insurer = response.data;
      } catch (error) {
        console.error("Error loading insurers:", error);
        this.insurer = [];
      }
    },

    checkOverdueCases(cases) {
      const tatAlertCases = [];
      const tatMaxCases = [];

      cases.forEach((item) => {
        if (item.fileStatus === "CLO" || item.fileStatus === "CANC") return;

        const tatValues = this.getTatValues(item.insurer, item.subRefType);
        if (!tatValues) return;

        const days = moment().diff(moment(item.dateOfAssign), "days");

        const isUserResponsible =
          item.adjuster === this.currentUser.id ||
          this.refType.some(
            (dept) =>
              dept.id === item.refType && dept.picID === this.currentUser.id
          );

        if (isUserResponsible) {
          if (days >= tatValues.tatMax) {
            tatMaxCases.push(item);
          } else if (days >= tatValues.tatAlert) {
            tatAlertCases.push(item);
          }
        }
      });

      if (tatAlertCases.length > 0 || tatMaxCases.length > 0) {
        this.overdueItems = {
          tatAlert: tatAlertCases,
          tatMax: tatMaxCases,
        };
        // Only show alert if enabled or not set in localStorage
        const showAlerts = localStorage.getItem("showOverdueAlerts");
        this.showOverdueAlert = showAlerts === null || showAlerts === "true";
        this.showOverdueOnLogin = this.showOverdueAlert;
      }
    },

    getTatValues(insurerId, subRefTypeId) {
      if (!insurerId || !subRefTypeId) return null;
      const filteredTat = this.tatCharts.find(
        (tat) => tat.insId === insurerId && tat.subDeptId === subRefTypeId
      );
      return filteredTat
        ? {
            tatAlert: parseInt(filteredTat.tatAlert),
            tatMax: parseInt(filteredTat.tatMax),
          }
        : null;
    },

    refreshActivity() {
      // Simulate loading recent activities
      this.recentActivities = [
        {
          id: 1,
          title: "New case assigned",
          timestamp: "5 minutes ago",
          icon: "mdi-file-plus",
          color: "primary",
        },
        {
          id: 2,
          title: "Case status updated",
          timestamp: "1 hour ago",
          icon: "mdi-update",
          color: "info",
        },
        {
          id: 3,
          title: "Report submitted",
          timestamp: "2 hours ago",
          icon: "mdi-file-check",
          color: "success",
        },
      ];
    },

    async toggleOverdueAlerts(value) {
      try {
        localStorage.setItem("showOverdueAlerts", value);
        this.showOverdueOnLogin = value;
      } catch (error) {
        console.error("Error saving overdue alert preference:", error);
      }
    },

    showProcessUpdate(text, isLoading = true, color = "info") {
      this.processSnackbar = {
        show: true,
        text,
        color,
        timeout: isLoading ? -1 : 3000,
        loading: isLoading,
      };
    },

    async refreshData() {
      this.showProcessUpdate("Refreshing data...");
      try {
        await this.loadStatistics();

        // Only refresh charts if they were previously loaded
        if (this.chartsLoaded) {
          await this.loadCharts();
        }

        this.showProcessUpdate("Data refreshed successfully", false, "success");
      } catch (error) {
        this.showProcessUpdate("Error refreshing data", false, "error");
        console.error("Error refreshing data:", error);
      }
    },

    async prepareChartData() {
      // Reset chart data to trigger skeleton loaders
      this.charts.forEach((chart) => {
        chart.data = null;
      });

      await Promise.all([
        this.prepareDepartmentChart(),
        this.prepareInsurerChart(),
        this.prepareStatusChart(),
      ]);
    },

    async prepareDepartmentChart() {
      try {
        const response = await CasefileDataService.getAll();
        const cases = response.data;

        // Group cases by department
        const deptCounts = {};
        cases.forEach((c) => {
          deptCounts[c.refType] = (deptCounts[c.refType] || 0) + 1;
        });

        // Get department names from refType data
        const deptNames = {};
        this.refType.forEach((dept) => {
          deptNames[dept.id] = dept.name;
        });

        this.departmentChartData = {
          labels: Object.keys(deptCounts).map(
            (id) => deptNames[id] || `Dept ${id}`
          ),
          datasets: [
            {
              data: Object.values(deptCounts),
              backgroundColor: [
                "#4CAF50",
                "#2196F3",
                "#FFC107",
                "#E91E63",
                "#9C27B0",
                "#00BCD4",
                "#FF5722",
                "#795548",
                "#607D8B",
                "#3F51B5",
              ],
            },
          ],
        };
      } catch (error) {
        console.error("Error preparing department chart:", error);
      }
    },

    async prepareInsurerChart() {
      try {
        const response = await CasefileDataService.getAll();
        const cases = response.data;

        // Group cases by insurer
        const insurerCounts = {};
        cases.forEach((c) => {
          insurerCounts[c.insurer] = (insurerCounts[c.insurer] || 0) + 1;
        });

        // Get top 5 insurers
        const topInsurers = Object.entries(insurerCounts)
          .sort(([, a], [, b]) => b - a)
          .slice(0, 5);

        // Get insurer names
        const insurerNames = {};
        this.insurer.forEach((ins) => {
          insurerNames[ins.id] = ins.name;
        });

        this.insurerChartData = {
          labels: topInsurers.map(
            ([id]) => insurerNames[id] || `Insurer ${id}`
          ),
          datasets: [
            {
              data: topInsurers.map(([, count]) => count),
              backgroundColor: "#2196F3",
            },
          ],
        };
      } catch (error) {
        console.error("Error preparing insurer chart:", error);
      }
    },

    async prepareStatusChart() {
      try {
        const response = await CasefileDataService.getAll();
        const cases = response.data;

        // Ensure stages are loaded
        if (!this.stages.length) {
          const stagesResponse = await StagesDataService.getAll();
          this.stages = stagesResponse.data;
        }

        // Group cases by status, excluding CLO status
        const statusCounts = {};
        cases.forEach((c) => {
          if (c.fileStatus !== "CLO") {
            // Exclude closed cases
            statusCounts[c.fileStatus] = (statusCounts[c.fileStatus] || 0) + 1;
          }
        });

        this.statusChartData = {
          labels: Object.keys(statusCounts),
          datasets: [
            {
              data: Object.values(statusCounts),
              backgroundColor: ["#4CAF50", "#FFC107", "#2196F3", "#F44336"],
            },
          ],
        };
      } catch (error) {
        console.error("Error preparing status chart:", error);
      }
    },

    getChartType(chart) {
      if (chart.component === "department-pie-chart") return "pie";
      if (chart.component === "status-donut-chart") return "donut";
      return "bar";
    },

    async refreshActivities() {
      try {
        const response = await CasefileDataService.getAll();
        const cases = response.data;

        // Sort cases by latest activity
        const sortedCases = cases
          .map((c) => {
            let activityType = "";
            let icon = "";
            let color = "";

            // Determine the latest activity and set appropriate icon
            if (c.dateOfClose) {
              activityType = "Case Closed";
              icon = "mdi-folder-check";
              color = "success";
            } else if (c.dateOfEdit) {
              activityType = "Report Edited";
              icon = "mdi-file-document-edit";
              color = "info";
            } else if (c.dateOfAssign) {
              activityType = "Adjuster Assigned";
              icon = "mdi-account-plus";
              color = "primary";
            }

            const prefix = c.prefix || "AA"; // Default to AA if prefix is undefined

            return {
              id: c.id,
              fileNo: `${prefix}-${c.id || "Case"}`, // Use case ID instead of serialNo
              action: activityType,
              timestamp: moment(c.dateOfAssign).fromNow(),
              icon,
              color,
              date: moment(c.dateOfAssign).valueOf(), // for sorting
            };
          })
          .filter((c) => c.fileNo && c.action) // Only include cases with valid file numbers and actions
          .sort((a, b) => b.date - a.date)
          .slice(0, 5); // Get only the last 5 activities

        this.recentActivities = sortedCases;
      } catch (error) {
        console.error("Error refreshing activities:", error);
        this.recentActivities = [];
      }
    },

    async refreshDeadlines() {
      // Implement this to fetch upcoming deadlines from your API
      this.upcomingDeadlines = [
        {
          id: 1,
          title: "Case Report Due",
          dueDate: "Tomorrow, 5:00 PM",
          priority: "error",
        },
        // Add more deadlines...
      ];
    },

    async refreshTasks() {
      // Implement this to fetch quick tasks from your API
      this.quickTasks = [
        {
          id: 1,
          title: "Review Investigation Report",
          category: "Reports",
          status: "Urgent",
          priority: "error",
          completed: false,
        },
        // Add more tasks...
      ];
    },

    async updateTask(task) {
      // Implement this to update task status in your API
      try {
        // API call to update task
        console.log("Task updated:", task);
      } catch (error) {
        console.error("Error updating task:", error);
      }
    },

    navigateToDepartment(deptId) {
      this.$router.push({
        name: "refTabDetail",
        params: { id: deptId },
      });
    },

    navigateToBranch(branchId) {
      this.$router.push({
        name: "brTabDetail",
        params: { id: branchId },
      });
    },

    // Add Quick Find methods
    findCaseById() {
      if (!this.caseIdSearch || !this.caseIdSearch.match(/^\d+$/)) return;

      // Save to recent searches
      this.addToRecentSearches(`Case #${this.caseIdSearch}`, {
        type: "case",
        id: this.caseIdSearch,
      });

      // Navigate to the case details
      this.$router.push({
        name: "vuexFile",
        params: { id: this.caseIdSearch },
      });

      // Clear the search field
      this.caseIdSearch = "";
    },

    async performAdvancedSearch() {
      let searchParams = {};
      let searchDescription = [];

      this.advancedSearchLoading = true;

      if (this.keywordSearch) {
        searchParams.keyword = this.keywordSearch;
        searchDescription.push(`"${this.keywordSearch}"`);
      }

      if (this.selectedDepartment) {
        searchParams.dept =
          this.selectedDepartment === "all" ? null : this.selectedDepartment;
        const deptName =
          this.selectedDepartment === "all"
            ? "All Departments"
            : this.filteredDepartments.find(
                (d) => d.id === this.selectedDepartment
              )?.name || "Department";
        searchDescription.push(deptName);
      }

      if (this.selectedBranch) {
        searchParams.branch =
          this.selectedBranch === "all" ? null : this.selectedBranch;
        const branchName =
          this.selectedBranch === "all"
            ? "All Branches"
            : this.filteredBranches.find((b) => b.id === this.selectedBranch)
                ?.name || "Branch";
        searchDescription.push(branchName);
      }

      if (this.selectedInsurer && this.selectedInsurer !== "all") {
        searchParams.insurer = this.selectedInsurer;
        const insurerName =
          this.insurer.find((i) => i.id === this.selectedInsurer)?.name ||
          "Insurer";
        searchDescription.push(insurerName);
      }

      if (this.selectedStatus) {
        searchParams.status =
          this.selectedStatus === "all" ? null : this.selectedStatus;
        const statusName =
          this.selectedStatus === "all"
            ? "All Statuses"
            : this.stages.find((s) => s.stageCode === this.selectedStatus)
                ?.name || this.selectedStatus;
        searchDescription.push(`Status: ${statusName}`);
      }

      try {
        // Add to recent searches
        this.addToRecentSearches(
          searchDescription.join(", ") || "Advanced Search",
          {
            type: "advanced",
            params: { ...searchParams },
          }
        );

        // Navigate to the new AdvancedSearch component with query parameters
        this.$router.push({
          name: "advancedSearch",
          query: searchParams,
        });
      } catch (error) {
        console.error("Search error:", error);
        this.$store.dispatch("alerts/setAlert", {
          text: "Error performing search",
          type: "error",
        });
      } finally {
        this.advancedSearchLoading = false;

        // Reset search fields after successful search
        this.keywordSearch = "";
        this.selectedDepartment = null;
        this.selectedBranch = null;
        this.selectedInsurer = null; // Add this line
        this.selectedStatus = null;
      }
    },

    // ...existing methods...

    // Helper method to filter cases by search criteria (implement when needed)
    filterCases(cases, criteria) {
      if (!cases || !Array.isArray(cases)) return [];

      return cases.filter((c) => {
        // Check department (refType)
        if (criteria.dept && c.refType !== criteria.dept) {
          return false;
        }

        // Check status
        if (criteria.status && c.fileStatus !== criteria.status) {
          return false;
        }

        // Check keyword (search in multiple fields)
        if (criteria.keyword) {
          const keyword = criteria.keyword.toLowerCase();
          const searchFields = [
            c.vehicleNo,
            c.claimNo,
            c.insComment,
            c.personInvolved,
            // Add any other searchable fields
          ];

          // Return true if any field contains the keyword
          return searchFields.some(
            (field) => field && field.toLowerCase().includes(keyword)
          );
        }

        return true;
      });
    },

    addToRecentSearches(text, data) {
      // Load existing searches from localStorage
      let searches = [];
      try {
        const savedSearches = localStorage.getItem("recentSearches");
        if (savedSearches) {
          searches = JSON.parse(savedSearches);
        }
      } catch (e) {
        console.error("Error loading recent searches:", e);
      }

      // Add new search to the beginning of the array
      searches.unshift({ text, data });

      // Limit to 10 recent searches
      searches = searches.slice(0, 10);

      // Save back to localStorage
      try {
        localStorage.setItem("recentSearches", JSON.stringify(searches));
        this.recentSearches = searches;
      } catch (e) {
        console.error("Error saving recent searches:", e);
      }
    },

    removeRecentSearch(index) {
      try {
        const savedSearches = localStorage.getItem("recentSearches");
        if (savedSearches) {
          const searches = JSON.parse(savedSearches);
          searches.splice(index, 1);
          localStorage.setItem("recentSearches", JSON.stringify(searches));
          this.recentSearches = searches;
        }
      } catch (e) {
        console.error("Error removing recent search:", e);
      }
    },

    clearRecentSearches() {
      try {
        localStorage.removeItem("recentSearches");
        this.recentSearches = [];
      } catch (e) {
        console.error("Error clearing recent searches:", e);
      }
    },

    repeatSearch(search) {
      if (!search || !search.data) return;

      switch (search.data.type) {
        case "case":
          this.$router.push({
            name: "vuexFile",
            params: { id: search.data.id },
          });
          break;
        case "advanced":
        case "filter":
          this.$router.push({
            name: "advancedSearch", // Change this from 'search' to 'advancedSearch'
            query: search.data.params,
          });
          break;
      }
    },

    async loadCharts() {
      this.chartsLoading = true;
      this.showProcessUpdate("Loading chart data...");
      try {
        await Promise.all([
          this.prepareDepartmentChart(),
          this.prepareInsurerChart(),
          this.prepareStatusChart(),
        ]);
        this.chartsLoaded = true;
        this.showProcessUpdate("Charts loaded successfully", false, "success");
      } catch (error) {
        this.showProcessUpdate("Error loading charts", false, "error");
        console.error("Error loading charts:", error);
      } finally {
        this.chartsLoading = false;
      }
    },

    async refreshSingleChart(index) {
      const chart = this.charts[index];
      chart.data = null; // Reset to show skeleton loader

      this.showProcessUpdate(`Refreshing ${chart.title.toLowerCase()}...`);
      try {
        switch (index) {
          case 0:
            await this.prepareDepartmentChart();
            break;
          case 1:
            await this.prepareInsurerChart();
            break;
          case 2:
            await this.prepareStatusChart();
            break;
        }
        this.showProcessUpdate(`${chart.title} refreshed`, false, "success");
      } catch (error) {
        this.showProcessUpdate(
          `Error refreshing ${chart.title.toLowerCase()}`,
          false,
          "error"
        );
        console.error(`Error refreshing chart ${index}:`, error);
      }
    },
  },

  async activated() {
    if (!this.processingComplete) {
      try {
        this.showProcessUpdate("Loading dashboard...");

        // Load core configurations
        await Promise.all([
          this.retrieveTatCharts(),
          this.retrieveDept(),
          this.retrieveSubRefType(),
          this.retrieveBranches(),
          this.retrieveStages(),
          this.retrieveIns(),
        ]);

        // Load statistics but not charts
        await this.loadStatistics();

        // Don't load charts by default
        // await this.prepareChartData();

        this.processingComplete = true;
        this.showProcessUpdate("Dashboard loaded", false, "success");
      } catch (error) {
        this.showProcessUpdate("Error loading dashboard", false, "error");
        console.error("Error in activated:", error);
      }
    }
  },

  created() {
    // Load recent searches from localStorage
    try {
      const savedSearches = localStorage.getItem("recentSearches");
      if (savedSearches) {
        this.recentSearches = JSON.parse(savedSearches);
      }
    } catch (e) {
      console.error("Error loading recent searches:", e);
      this.recentSearches = [];
    }
  },
};
</script>

<style>
.dashboard-container {
  padding: 16px;
  width: 100%;
  max-width: 100%;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}

/* Preserve existing styles */
.dashboard-container {
  padding: 16px;
}

.welcome-bar {
  grid-column: 1 / -1;
  width: 100%;
}

.notification-bar {
  grid-column: 1 / -1;
  width: 100%;
}

.main-area {
  grid-column: 1;
}

.base-area {
  grid-column: 1 / -1;
  margin-top: 16px;
}

.v-card {
  margin-bottom: 16px;
}

/* Remove the fixed height constraints */
.performance-cards .v-card {
  height: auto;
}

/* Adjust notification bar for quick actions */
.notification-bar .v-btn {
  min-width: 120px;
}

/* Add these new styles */
.quick-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.quick-actions .v-chip {
  height: 32px;
}

/* Update quick actions styles */
.quick-actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.quick-actions .v-btn {
  text-transform: none;
  letter-spacing: normal;
  font-weight: 400;
}

.status-info {
  flex-wrap: wrap;
}

.v-chip-group .v-chip {
  margin-right: 8px;
  margin-bottom: 8px;
}

.v-list-item__action .v-btn {
  margin: 0;
}

.v-list-item__subtitle .v-icon {
  opacity: 0.8;
}

.v-overlay__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.v-card {
  position: relative;
}

/* Update progress circular styles */
.v-progress-circular {
  margin: 1rem;
  color: primary;
}

/* Add responsive utilities */
.d-flex-responsive {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.flex-grow {
  flex-grow: 1;
}

.text-truncate-mobile {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Add these new styles for performance cards */
.performance-card {
  width: 100%;
  margin-bottom: 8px;
}

/* Responsive Layout - Mobile First Approach */
@media (max-width: 767px) {
  .dashboard-container {
    padding: 8px;
  }

  .dashboard-grid {
    grid-template-columns: 1fr;
    gap: 8px;
  }

  .main-area,
  .performance-cards {
    grid-column: 1;
  }

  .performance-cards {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .performance-cards .v-card,
  .performance-card {
    width: 100% !important;
    margin-bottom: 8px !important;
  }

  .welcome-bar .text-h4 {
    font-size: 1.25rem !important;
  }

  .notification-bar .v-col {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    padding: 4px;
  }

  .quick-actions {
    flex-direction: column;
    width: 100%;
    margin-top: 8px;
  }

  .quick-actions .v-btn {
    width: 100%;
    margin: 4px 0;
  }

  .quick-actions .v-divider {
    display: none;
  }

  .status-info {
    flex-direction: column;
    width: 100%;
  }

  .status-info .v-chip {
    width: 100%;
    justify-content: center;
    margin: 4px 0;
  }

  .v-card-text {
    padding: 12px;
  }

  .v-card-title {
    padding: 12px;
    font-size: 1rem !important;
  }
}

/* Tablet/iPad Layout */
@media (min-width: 768px) and (max-width: 1024px) {
  .dashboard-grid {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .main-area,
  .performance-cards {
    grid-column: 1;
  }

  .performance-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }

  .performance-card {
    margin: 0 !important;
  }

  .notification-bar .v-col {
    flex: 0 0 50% !important;
    max-width: 50% !important;
    padding: 6px;
  }

  .welcome-bar .text-h4 {
    font-size: 1.5rem !important;
  }

  .quick-actions {
    justify-content: flex-end;
  }
}

/* Large Tablet/Small Desktop */
@media (min-width: 1025px) and (max-width: 1264px) {
  .dashboard-grid {
    grid-template-columns: 2fr 1fr;
    gap: 16px;
  }

  .performance-cards {
    grid-template-rows: auto;
    gap: 12px;
  }
}

/* Fix for nested cards and lists */
@media (max-width: 1024px) {
  .v-list-item {
    padding: 8px;
  }

  .v-list-item__title {
    font-size: 0.9rem;
  }

  .v-list-item__subtitle {
    font-size: 0.8rem;
  }

  .v-chip {
    height: 24px;
  }

  .v-chip--x-small {
    font-size: 0.625rem;
  }

  .performance-card .v-card {
    margin: 0 !important;
    box-shadow: none !important;
  }

  .performance-card .v-list {
    padding: 0;
  }
}

/* Base area responsiveness */
@media (max-width: 1024px) {
  .base-area {
    margin-top: 8px;
  }

  .base-area .v-row {
    margin: 0;
  }

  .base-area .v-col {
    padding: 4px;
  }
}

/* Add new styles for analytics section */
.analytics-section {
  margin-top: 0;
}

.analytics-section .v-card {
  height: 100%;
}

/* Chart specific styles */
.chart-card {
  height: 300px;
  display: flex;
  flex-direction: column;
}

.chart-container {
  flex: 1;
  position: relative;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-container > * {
  width: 100%;
  height: 100%;
}

.analytics-section .v-row + .v-row {
  margin-top: 16px;
}

/* Add these new styles for skeleton loader */
.chart-container {
  min-height: 200px;
}

/* Add these new styles */
.activity-card {
  height: 100%;
}

.activity-card .v-list-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.activity-card .v-list-item:last-child {
  border-bottom: none;
}

.activity-card .v-list-item__subtitle {
  margin-top: 4px;
}

/* Quick Find card styles */
.quick-find-card {
  position: relative;
}

.quick-find-card .v-card__title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding-bottom: 8px;
}

.quick-find-card .v-expansion-panel {
  box-shadow: none;
  background: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin-bottom: 8px;
}

.quick-find-card .v-expansion-panel:before {
  box-shadow: none !important;
}

.quick-find-card .v-expansion-panel-header {
  padding: 8px 16px;
  min-height: auto;
}

.quick-find-card .v-expansion-panel-content__wrap {
  padding: 8px 16px 16px;
}

/* Make search history chips look nice */
.quick-find-card .v-chip.v-size--small {
  height: 24px;
}
</style>
