<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1.2,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 10,
            bottom: 0,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                padding: 10,
              },
            },
          ],
          xAxes: [
            {
              display: false, // Hide x-axis labels
            },
          ],
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
          callbacks: {
            title: (tooltipItems, chartData) => {
              return chartData.labels[tooltipItems[0].index];
            },
            label: (tooltipItem) => {
              return `Cases: ${tooltipItem.value}`;
            },
          },
        },
      }),
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
